var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('PixIntegradorPagamentoDialog',{ref:"showPagamentoDialog",attrs:{"propTipoCobranca":2}}),_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Financeiro")])]),_c('v-card-text',[[_c('v-data-table',{staticClass:"ma-1",attrs:{"multi-sort":"","headers":_vm.financeiroParcelaHeaders,"items":_vm.financeiroParcelasItems,"loading-text":"Carregando...","loading":_vm.loading,"footer-props":{
            itemsPerPageOptions: [-1]
          },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.vencimento",fn:function(ref){
          var item = ref.item;
return [(item.vencimento === '1970-01-01')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.ultimo_pagamento_data))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.vencimento))+" ")])]}},{key:"item.valor_original",fn:function(ref){
          var item = ref.item;
return [(item.financeiro_parcela_status_id !== _vm.getFinanceiroParcelaStatusIdPago())?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor_original))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor_pago))+" ")])]}},{key:"item.is_active",fn:function(ref){
          var item = ref.item;
return [(item.is_active)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [(item.financeiro_parcela_status_id != _vm.getFinanceiroParcelaStatusIdEmAberto())?_c('v-chip',{staticClass:"mt-2",attrs:{"label":"","color":_vm.getStatusFinanceiroParcelaStatus(item.financeiro_parcela_status_id).color}},[_vm._v(" "+_vm._s(_vm.getStatusFinanceiroParcelaStatus(item.financeiro_parcela_status_id).name)+" ")]):_c('v-btn',{staticClass:"warning",on:{"click":function($event){return _vm.showPix(item)}}},[_vm._v(" pagar com pix ")]),(new Date(item.vencimento) < new Date() && item.financeiro_parcela_status_id == _vm.getFinanceiroParcelaStatusIdEmAberto())?_c('v-chip',{staticClass:"error ml-1 blink_me",attrs:{"label":""}},[_vm._v(" Parcela em atraso ")]):_vm._e()]}},{key:"item.nota",fn:function(ref){
          var item = ref.item;
return [(item.financeiro_parcela_movimentacao_plugnotas_id)?_c('PlugnotasConsultaEmiteButton',{attrs:{"propPlugnotasId":item.financeiro_parcela_movimentacao_plugnotas_id,"propPlugnotasStatus":item.financeiro_parcela_movimentacao_plugnotas_status,"propFinanceiroParcelaMovimentacaoId":item.financeiro_parcela_movimentacao_id,"propTipoDocumento":1}}):_vm._e()]}}],null,true)})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }